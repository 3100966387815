export default {
    gender:null,
    language:[],
    country:null,
    state:null,
    pmOfcontact:null,
    ptOfDayContact:null,
    healthCondition:null,
    relation:null,
    designations:null,
    programList:null,
    deviceType:null,
    program:null,
    timeline:null,
    taskPriority:[],
    network:null,
    specialization:[],
    taskCategory:[],
    successMsg:null, 
    insuranceName:null,
    insuranceType:null,
    documentTags:null,
    documentTypes:null,
    loadingStatus:false,
    duration:null,
    typeOfVisit:null,
    staffList:null,
    noteTypes:null,
    noteCategories:null,
    programCategories:null,
    vitalFieldsList:null,
    vitalFieldsByDeviceId:null,
    frequencyTypes:null,
    modules:null,
    allPatientsList:null,
    allStaffList:null,
    activeCptCodes:null,
    checkChangeInput:false,
    getNotifications:null,
    searchTable:null,
    notificationList:null,
    dropdownLoadingStatus:false,
    dropdownListing:null,
    loadingTableStatus:null,
    actionTrack:null,
    orderTable:null,
    getNotificationsList:null,
    filter:null,
    removalReason:[],
    timeApprovalStatus:null,
    apiCounter:0,
    bugCategory:null,
    // phone msaking objects
    bindProps:{
        mode:"national",
        defaultCountry: "US",
        disabledFetchingCountry: true,
        disabled: false,
        disabledFormatting: true,
        placeholder: "Enter a phone number",
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        autocomplete: "off", 
        onlyCountries:['US'],
        
    },
    escalationType:null,
    toScroll:false,
    dateFilter:null,
    taskType:null,
    taskStatus: [],
    otherFilters:null,
    CPTCodeStatus:null,
    showStartTimer:false,
    showPauseTimer:true,
    showResumeTimer:false,
    loadingTimelineStatus:false,
    messageType:null,
    notificationsMeta:null,
}