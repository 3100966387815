export const messages={
    //alert messages
    patientlogMessage:'Are you sure you want to cancel? No time will be recorded for this Patient.',
    timerMessage:'Are you sure you want to change the patient? Your time will be lost for this Patient.',
    access:"You don't have access",
    permissionsError:'You don’t have permission! Contact your Admin',
    resetPassword:'You want to reset password.',
    warning:'Are you sure ?',
    deleteWarning:'Are you sure you want to delete?',
    restoreWarning:'Do you want to Restore ?',
    modalWarning:'Are you sure you want to discard your changes?',
    formSuccess:'Data successfully added!',
    fieldsRequired:'Please fill all required(*) fields.',
    startTimeAndEndTime:'End time should be greater than start time.',
    modalWarningDeleteTitle:"Are you sure you want to discard your changes?",
    modalWarningCancelTitle:"Do you want to Delete ?"
}