export const patientCareCoordinatorsList = (state, data) => {
  state.latestCareCoordinator = data[0]
  state.patientCareCoordinatorsList = data.map(item => {
    item.isPrimary = item.isPrimary ? 'Yes' : 'No'
    item.type = item.type ? 'Care Coordinator' : 'Health Team'
    return item
  })
}

export const careTeamList = (state, data) => {
  state.careTeamList = data.map(item => {
    item.isPrimary = item.isPrimary ? 'Yes' : 'No'
    item.type = item.type ? 'Care Coordinator' : 'Health Team'
    return item
  })
  state.latestHealthTeam = data[0]
}

export const physiciansList = (state, data) => {
  state.physiciansList = data.map(item => {
    item.isPrimary = item.isPrimary ? 'Yes' : 'No'
    item.type = item.type ? 'Care Coordinator' : 'Health Team'
    return item
  })
  state.latestPhysician = data[0]
  state.latestHealthTeam = data[0]
}

export const deletePatientCareCoordinator = (state, data) => {
  state.deletePatientCareCoordinator = data
}

export const patientCareCoordinatorDetails = (state, data) => {
  // console.log('staff data', data.staff)
  data.isPrimary = data.isPrimary == 1 ? true : false
  state.patientCareCoordinatorDetails = data
}

export const updatePatientCareCoordinator = (state, data) => {
  state.updatePatientCareCoordinator = data
}

